<style>
    /* Chrome, Safari, Edge, Opera */
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
<h1 mat-dialog-title>2FA Code Required</h1>
<div mat-dialog-content style="padding-top: 2em;">
    <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput type="number" [(ngModel)]="twoFactor" (keydown.enter)="close2FA()" cdkFocusInitial/>
    </mat-form-field>
</div>
<div mat-dialog-actions class="flex row" style="justify-content: space-evenly;">
    <button mat-button (click)="cancel2FA()">Cancel</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="twoFactor" type="submit">Verify</button>
</div>