<mat-card class="card-outer flex column">

        <div class="top-bar-container flex column">
            <div class="top-bar flex row">
                <mat-form-field appearance="outline" class="list" *ngIf="!orgsHidden && msps.length > 0">
                    <mat-label>MSP</mat-label>
                    <mat-select [(value)]="current_msp" (selectionChange)="changeMsp()">
                        <mat-select-trigger>
                            {{current_msp? current_msp.name: "-- None --"}}
                        </mat-select-trigger>
                        <mat-option value="">-- None --</mat-option>
                        <mat-option *ngFor="let msp of msps" [value]="msp">
                            <div class="custom-option">
                                <mat-chip-option class="role">{{msp.role}}</mat-chip-option>
                                {{msp.name}} 
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="list" *ngIf="!orgsHidden">
                    <mat-label>Organization</mat-label>
                    <mat-select [(value)]="current_org" (selectionChange)="changeOrg()">
                        <mat-select-trigger>
                            {{current_org.name}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let org of orgs" [value]="org">
                            <div class="custom-option">
                            <mat-chip-option class="role">{{org.role}}</mat-chip-option>
                            {{org.name}} 
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="list">
                    <mat-label>Site</mat-label>
                    <mat-select [(value)]="site_id" (selectionChange)="changeSite()" [disabled]="sitesDisabled">
                        <mat-option *ngIf="scope=='org'" value="org">-- Org PSKs --</mat-option>
                        <mat-option *ngFor="let site of sites" [value]="site.id">
                            {{site.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="list">
                    <mat-label>SSID</mat-label>
                    <mat-select [(value)]="ssid" (selectionChange)="changeWlan()" [disabled]="wlansDisabled">
                        <mat-option value="">Any</mat-option>
                        <mat-option *ngFor="let wlan of wlans" [value]="wlan.ssid">
                            {{wlan.ssid}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div appearance="outline" class="list" style="margin: auto !important;">
                    <mat-checkbox [(ngModel)]="filters_enabled" (ngModelChange)=getPsks()>
                        <span>Enable Filters</span>
                    </mat-checkbox>
                    <span class="material-icons tooltip" matTooltip="This option will load localy all the PSKs, and allow you to search specific PSK based on filters. Depending on the number of PSKs, it can take some time..." style="font-size: 1.5em; margin: 5px">
                    help_outline
                </span>
                </div>

                <button mat-raised-button color="accent" class="create" (click)="openCreate()" style="margin: auto 20px auto auto" [disabled]="createDisabled || topBarLoading">
                    <mat-icon class="material-icons" style="margin: auto 10px auto auto;">add</mat-icon>
                    <span>Create PSK</span>
                </button>
            </div>


            <div style="width: 100%; max-width: 150em; margin: auto;height: 4px; background-color: rgb(111 157 43);" *ngIf="topBarLoading == false">
            </div>
            <mat-progress-bar mode="indeterminate" color="accent" *ngIf="topBarLoading == true" style="width: 100%; max-width: 150em; margin: auto;"></mat-progress-bar>

        </div>

        <div class="psk-container mat-elevation-z8">
            <div class="psk-table-container flex column" *ngIf="filters_enabled == false">
                <div class="flex row" style="justify-content: flex-end; background-color: white;">
                <mat-paginator [length]="resultsLength" [pageSizeOptions]="[10, 25, 50, 100]" style="min-width: 40em;"></mat-paginator>

                </div>

                <div class="table-container">
                <table mat-table [dataSource]="psks">
                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="material-icons" *ngIf="element.expire_time > 0 && element.expire_time <= now" style="user-select: none; font-size: 1.2em;margin: 1em 0;color: gray;" matTooltip="deactivated">
                            highlight_off
                        </span>
                            <span class="material-icons" *ngIf="!element.expire_time || element.expire_time > now" style="user-select: none; font-size: 1.2em;margin: 1em 0;" matTooltip="activated">
                            check_circle
                        </span>
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> NAME </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="user_email">
                        <th mat-header-cell *matHeaderCellDef> EMAIL </th>
                        <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
                    </ng-container>

                    <!-- SSID Column -->
                    <ng-container matColumnDef="ssid">
                        <th mat-header-cell *matHeaderCellDef> SSID </th>
                        <td mat-cell *matCellDef="let element"> {{element.ssid}} </td>
                    </ng-container>

                    <!-- VLAN Column -->
                    <ng-container matColumnDef="vlan_id">
                        <th mat-header-cell *matHeaderCellDef> VLAN ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.vlan_id}} </td>
                    </ng-container>

                    <!-- CREATOR Column -->
                    <ng-container matColumnDef="created_by">
                        <th mat-header-cell *matHeaderCellDef> CREATED BY </th>
                        <td mat-cell *matCellDef="let element"> {{element.created_by}} </td>
                    </ng-container>

                    <!-- Expiraation Column -->
                    <ng-container matColumnDef="expire_time">
                        <th mat-header-cell *matHeaderCellDef> EXPIRE TIME </th>
                        <td mat-cell *matCellDef="let element" [style.color]="element.expire_time > 0 && element.expire_time <= now  ? 'gray' : null">
                            <span *ngIf="element.expire_time > 0"> {{element.expire_time * 1000 | date:'short'}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="button-container">
                                <button mat-stroked-button class="stroked-button edit" (click)="openEdit(element)" matTooltip="Edit">
                                    <mat-icon class="material-icons">create</mat-icon>
                                </button>
                                <button mat-stroked-button class="stroked-button edit" (click)="openEmail(element)" matTooltip="Send by Email">
                                    <mat-icon class="material-icons">email</mat-icon>

                                </button>
                                <button mat-stroked-button class="stroked-button qrcode" (click)="openQrcode(element)" matTooltip="Show QRCode">
                                    <mat-icon class="material-icons">qr_code_2</mat-icon>

                                </button>
                                <button mat-stroked-button class="stroked-button delete" (click)="openDelete(element)" matTooltip="Delete">
                                    <mat-icon class="material-icons">delete_forever</mat-icon>

                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            </div>



            <div class="psk-table-container flex column" *ngIf="filters_enabled == true">
                <div class="flex row">
                    <mat-form-field *ngIf="filters_enabled" style="margin: 0;background-color: white;width: 100%;">
                        <mat-label>Filter</mat-label>
                        <input matInput [disabled]="filters_enabled == false" (keyup)="applyFilter($event)" placeholder="Ex. psk name" #input>
                    </mat-form-field>
                    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[10, 25, 50, 100]" style="min-width: 40em;"></mat-paginator>
                </div>
                <div class="table-container">
                <table mat-table [dataSource]="filteredPskDatase">
                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element">
                            <span class="material-icons" *ngIf="element.expire_time > 0 && element.expire_time <= now" style="user-select: none; font-size: 1.2em;margin: 1em 0;color: gray;" matTooltip="deactivated">
                            highlight_off
                        </span>
                            <span class="material-icons" *ngIf="!element.expire_time || element.expire_time > now" style="user-select: none; font-size: 1.2em;margin: 1em 0;" matTooltip="activated">
                            check_circle
                        </span>
                        </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> NAME </th>
                        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- Email Column -->
                    <ng-container matColumnDef="user_email">
                        <th mat-header-cell *matHeaderCellDef> EMAIL </th>
                        <td mat-cell *matCellDef="let element"> {{element.user_email}} </td>
                    </ng-container>

                    <!-- SSID Column -->
                    <ng-container matColumnDef="ssid">
                        <th mat-header-cell *matHeaderCellDef> SSID </th>
                        <td mat-cell *matCellDef="let element"> {{element.ssid}} </td>
                    </ng-container>

                    <!-- VLAN Column -->
                    <ng-container matColumnDef="vlan_id">
                        <th mat-header-cell *matHeaderCellDef> VLAN ID </th>
                        <td mat-cell *matCellDef="let element"> {{element.vlan_id}} </td>
                    </ng-container>

                    <!-- CREATOR Column -->
                    <ng-container matColumnDef="created_by">
                        <th mat-header-cell *matHeaderCellDef> CREATED BY </th>
                        <td mat-cell *matCellDef="let element"> {{element.created_by}} </td>
                    </ng-container>

                    <!-- Expiraation Column -->
                    <ng-container matColumnDef="expire_time">
                        <th mat-header-cell *matHeaderCellDef> EXPIRE TIME </th>
                        <td mat-cell *matCellDef="let element" [style.color]="element.expire_time > 0 && element.expire_time <= now  ? 'gray' : null">
                            <span *ngIf="element.expire_time > 0"> {{element.expire_time * 1000 | date:'short'}}
                            </span>
                        </td>
                    </ng-container>

                    <!-- Action Column -->
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let element">
                            <div class="button-container">
                                <button mat-stroked-button class="stroked-button edit" (click)="openEdit(element)" matTooltip="Edit">
                                    <mat-icon class="material-icons">create</mat-icon>
                                </button>
                                <button mat-stroked-button class="stroked-button edit" (click)="openEmail(element)" matTooltip="Send by Email">
                                    <mat-icon class="material-icons">email</mat-icon>

                                </button>
                                <button mat-stroked-button class="stroked-button edit" (click)="openQrcode(element)" matTooltip="Show QRCode">
                                    <mat-icon class="material-icons">qr_code_2</mat-icon>

                                </button>
                                <button mat-stroked-button class="stroked-button delete" (click)="openDelete(element)" matTooltip="Delete">
                                    <mat-icon class="material-icons">delete_forever</mat-icon>

                                </button>
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            </div>
            <div *ngIf="topBarLoading || loading" class="table-info">Loading...</div>
            <div *ngIf="!topBarLoading && !loading && !site_id" class="table-info">Please select a site</div>
            <div *ngIf="!topBarLoading && !loading && site_id && psks.length==0" class="table-info">There is not PSK configured</div>


            <div class="loading flex row" *ngIf="loading">
                <div class="flex column">
                    <mat-spinner strokeWidth=3></mat-spinner>
                </div>
            </div>
        </div>


</mat-card>