<h1 mat-dialog-title>Email PSK</h1>
<div mat-dialog-content class="flex column">

    <form [formGroup]="frmEmail" class="create-form flex column">
        <mat-form-field appearance="outline">
            <mat-label>NAME</mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>EMAIL</mat-label>
            <input matInput formControlName="user_email" (keydown.enter)="confirm()" />
        </mat-form-field>
    </form>

</div>
<div mat-dialog-actions style="justify-content: space-around;" class="flex row">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="accent" cdkFocusInitial (click)="confirm()">Send Email</button>
</div>