<style>
    /* Chrome, Safari, Edge, Opera */
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* Firefox */
    
    input[type=number] {
        -moz-appearance: textfield;
    }
    
    button:disabled {
        color: rgba(0, 0, 0, 0.7) !important;
        opacity: 0.5 !important;
    }
</style>
<h1 mat-dialog-title>Create New PSK</h1>
<form [formGroup]="frmPsk" class="create-form">
    <div mat-dialog-content class="flex column">
        <!-- SSID configuration  -->
        <mat-form-field appearance="outline">
            <mat-label>SSID</mat-label>
            <mat-select formControlName="ssid" required (selectionChange)="changeWlan()">
                <mat-option *ngFor="let wlan of data.wlans" [value]="wlan.ssid">
                    {{wlan.ssid}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- Name configuration  -->
        <mat-form-field appearance="outline">
            <mat-label>NAME</mat-label>
            <input matInput required formControlName="name" />
        </mat-form-field>
        <!-- EMAIL configuration  -->
        <mat-form-field appearance="outline">
            <mat-label>EMAIL</mat-label>
            <input matInput formControlName="user_email" />
        </mat-form-field>

        <!-- PSK configuration  -->
        <div class="flex row" style="align-items: center;">
            <mat-checkbox formControlName="renewable" (ngModelChange)="changeRenewable()">
                Enable Renewable PSK
            </mat-checkbox>
            <span class="material-icons tooltip" matTooltip="The same PSK will be generated every time for the same PSK Name and the same SSID" style="cursor: default; font-size: medium; margin-left: .5em;">
                help
            </span>
        </div>
        <div class="flex row">
            <mat-form-field appearance="outline" style="width: 400px;">
                <mat-label>PSK</mat-label>
                <input matInput required [type]="passwordFieldType" formControlName="psk" />

                <button *ngIf="passwordFieldType=='password'" matSuffix mat-icon-button aria-label="show password" (click)="toggleShowPassword()" [disabled]="renewable">
                    <mat-icon style="color: rgba(0,0,0,.6);">visibility</mat-icon>
                </button>
                <button *ngIf="passwordFieldType=='text'" matSuffix mat-icon-button aria-label="show password" (click)="toggleShowPassword()" [disabled]="renewable">
                    <mat-icon style="color: rgba(0,0,0,.6);">visibility_off</mat-icon>
                </button>
            </mat-form-field>
            <button mat-stroked-button (click)="generatePsk()" style="height: 41px;margin: 5px;color: #0d60aa;border: 1px solid;" [disabled]="renewable">Generate PSK</button>
        </div>

        <!-- VLAN configuration  -->
        <mat-form-field appearance="outline" *ngIf="vlan_ids.length > 0">
            <mat-label>VLAN</mat-label>
            <input matInput type="number" formControlName="vlan_id" />
            <mat-select formControlName="vlan_id">
                <mat-option value="0">--</mat-option>
                <mat-option *ngFor="let vlan_id of vlan_ids" [value]="vlan_id">
                    {{vlan_id}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div style="border-radius: 7px;padding: 0 0.5em;height: 67px;display: flex;flex-direction: column;margin: 0 0 1em 0;color: rgba(0, 0, 0, 0.38);justify-content: space-around;border: 1px solid rgba(0,0,0,.12);" *ngIf="vlan_ids.length == 0">
            <mat-label>VLAN</mat-label>
            <div>This SSID is not configured to support VLANs</div>
        </div>
    </div>
</form>
    <!-- Duration configuration  -->

    <div style="border: 1px solid rgba(0,0,0,.12);border-radius: 5px;padding: 1em;">
        <div style="display: flex;flex-direction: column;font: inherit;">
            <label style="margin-bottom: 1em;color: rgba(0,0,0,.6);">EXPIRE TIME</label>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="expire_method" style="margin: 5px;display: flex;flex-direction: row;justify-content: space-evenly;">
                <mat-radio-button value="none">None</mat-radio-button>
                <mat-radio-button value="duration">Duration</mat-radio-button>
                <mat-radio-button value="date">Date / Time</mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="flex row" *ngIf="expire_method=='duration'" style="justify-content: space-evenly;margin: 1em 1em 0;">
            <mat-form-field appearance="outline">
                <mat-label>Expire In </mat-label>
                <input matInput [(ngModel)]="duration" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="duration_period">
                    <mat-option value="hours">Hours</mat-option>
                    <mat-option value="days">Days</mat-option>
                    <mat-option value="days">Weeks</mat-option>
                    <mat-option value="months">Months</mat-option>
                    <mat-option value="years">Years</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex row" *ngIf="expire_method=='date'" style="justify-content: space-evenly;margin: 1em 1em 0;">
            <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="picker" [min]="min_date" placeholder="Choose a date" [formControl]="dateControl">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker color="accent">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>

    <!-- Max Usage configuration  -->
    <div *ngIf="!max_usage_disabled" style="border: 1px solid rgba(0,0,0,.12);border-radius: 5px;padding: 1em;">
        <div style="display: flex;flex-direction: column;font: inherit;">
            <label style="margin-bottom: 1em;color: rgba(0,0,0,.6);">MAX USAGE</label>
            <div class="flex row" style="align-items: center;">
                <mat-radio-group aria-label="Select an option" [(ngModel)]="max_usage_required">
                    <mat-radio-button value="false">Unlimited Devices</mat-radio-button>
                    <mat-radio-button value="true">Set number of devices</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <form *ngIf="max_usage_required=='true'" class="flex column">
            <mat-form-field appearance="outline">
                <mat-label>Max concurrent connections</mat-label>
                <input matInput type="number" [(formControl)]="maxUsageControl" min="1" max="100"/>
            </mat-form-field>
        </form>
    </div>



<div mat-dialog-actions style="justify-content: space-around;" class="flex row">
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-raised-button color="accent" cdkFocusInitial (click)="confirm()" *ngIf="editing==true" [disabled]="frmPsk.invalid || maxUsageControl.invalid">Update</button>
    <button mat-raised-button color="accent" cdkFocusInitial (click)="confirm()" *ngIf="editing==false" [disabled]="frmPsk.invalid || maxUsageControl.invalid">Create</button>
</div>